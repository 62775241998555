import React, { Component } from 'react';
import axios from 'axios';
import Timeline from '../templates/timeline';

class ArticleViewer extends Component {
  constructor() {
    super();
    this.state = {
      path: '/nuclear-weapons/timelines/atomic-bomb-development',
      data: false,
    };
  }
  componentDidMount() {
    this.updateData();
  }

  updateData() {
    const contentEndpoint =
      'http://dev-outrider.pantheonsite.io/api/get-items?type=timeline';

    axios
      .get(`${contentEndpoint}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ data: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
  formUpdate = e => {
    e.preventDefault();
    this.updateData();
    if (this.input.value) {
      this.setState({ path: this.input.value });
    }
  };

  render() {
    if (!this.state.data || !this.state.path) {
      return null;
    }
    return (
      <div>
        <form
          onSubmit={this.formUpdate}
          style={{
            position: 'absolute',
            right: '5px',
            top: '5px',
            width: '400px',
            background: 'rgba(0, 0, 0, .9)',
            zIndex: '1000',
            padding: '20px',
            color: '#fff',
            borderRadius: '3px',
          }}
        >
          <p style={{ margin: '0 0 10px', fontWeight: 'bold' }}>
            Update Content
          </p>
          <input
            type="text"
            ref={el => (this.input = el)}
            placeholder={this.state.path}
            style={{
              display: 'block',
              width: '100%',
              margin: '5px 0',
              fontSiz: '14px',
              background: '#333',
              color: '#fff',
              border: 'none',
              padding: '3px',
            }}
          />
          <input
            type="submit"
            value="Refresh"
            style={{
              borderRradius: '2px',
              background: '#555',
              color: '#fff',
              fontSize: '13px',
              fontWeight: 'bold',
              padding: '5px 10px',
              border: 'none',
            }}
          />
        </form>
        <Timeline
          handleScrollHeader={this.props.handleScrollHeader}
          data={{
            itemsJson: this.state.data.filter(
              item => item.path === this.state.path
            )[0],
          }}
        />
      </div>
    );
  }
}

export default ArticleViewer;
